<template>
    <div class="container">
        <div v-if="isLoading">
            <Vue3Lottie :animationData="SpinPrimary" class="animation-icon" />
            <p>{{ $t('common.pleaseWaitAccountCreated') }}</p>
        </div>
        <div v-if="error">
            <CrossSvg class="cross-icon" />
            <p class="error-text">{{ error }}</p>
        </div>
    </div>
</template>

<script>
    import Cookies from 'js-cookie';
    import { AuthApi } from '@/api';
    import SpinPrimary from '@/assets/animations/spin-primary.json';
    import CrossSvg from '@/assets/icons/buttons/cross-black.svg?inline';
    import { Vue3Lottie } from 'vue3-lottie';
    import { mapMutations, mapGetters } from 'vuex';
    import LocalStorage from '@/helpers/LocalStorageHelper';
    import { ConversionEventNameEnum } from '@/utils/enums';

    export default {
        name: 'Social',
        components: { Vue3Lottie, CrossSvg },
        data() {
            return {
                SpinPrimary,
                isLoading: true,
                error: '',
            };
        },
        computed: {
            ...mapGetters('auth', ['returnUrl'])
        },
        methods: {
            ...mapMutations('auth', ['setUser']),

            async socialUserCallback(provider, payload) {
                try {
                    const referralUserId = LocalStorage.show('referralUserId');
                    payload.referralUserId = referralUserId ?? null;

                    const response = await AuthApi.socialCallback(provider, payload);
                    
                    Cookies.set('Authorization', `${response.authorization.type} ${response.authorization.token}`);
                    
                    this.setUser(response.user);

                    LocalStorage.delete('referralUserId');

                    fbq('track', ConversionEventNameEnum.COMPLETE_REGISTRATION, {
                        content_name: `${provider}_registration`,
                    });

                    if(this.returnUrl) {
                        this.$router.push(this.returnUrl);
                    } else {
                        this.$router.push({ name: 'main' });
                    }
                } catch (error) {
                    this.error = error.error;
                } finally {
                    this.isLoading = false;
                }
            },
        },
        created() {
            this.socialUserCallback(this.$route.params.provider, { code: this.$route.query.code });
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-center-justify-center;

        .animation-icon {
            width: 500px;
        }

        p {
            text-align: center;
        }

        div {
            @include column-align-center-justify-center;
            .cross-icon {
                width: 150px;
                height: 150px;

                margin-bottom: 40px;

                & :deep() {
                    path {
                        fill: $danger;
                    }
                }
            }

            .error-text {
                color: $danger;
            }

            @include media($lg) {
                p {
                    font-size: $font-18;
                }
            }
        }
    }
</style>
